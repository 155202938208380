.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  margin-top: -7rem;
}

.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  gap: 4rem;
  color: white;
}

.f-icons {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 600px) {
  .footer{
    height: 14rem;
  }

  .footer>:nth-child(1){
    height: 25rem;
  }
  .f-content {
    transform: scale(0.8);
    gap: 3rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1023px) {
  .footer{
    height: 30rem;
  }

  .footer>:nth-child(1){
    height: 35rem;
  }
  .f-content {
    gap: 3rem;
  }
.f-content>:nth-child(1){
  font-size: 1.2rem;
}
}

@media screen and (orientation: landscape) and (max-width: 961px) {
  .footer{
    height: 17rem;
  }

  .footer>:nth-child(1){
    height: 35rem;
  }

}

@media screen and (min-width: 962px) and (max-width: 1023px) and (orientation: landscape) {
  .footer{
    height: 20rem;
  }

  .footer>:nth-child(1){
    height: 35rem;
  }

}
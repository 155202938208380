.services {
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 90vh;
  margin-bottom: 8rem;
  margin-top: 9rem;
  margin-bottom: 12rem;
}

.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}

.awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}

.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}

.cards {
  position: relative;
}

.cards > * {
  position: absolute;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}

.s-blur2 {
  left: 14rem;
  top: 8rem;
}

@media screen and (max-width: 600px) {
  .services {
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 60rem;
    padding: 0;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }
  .cards > * {
    position: static;
  }

  .blur {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 1023px) {
  .services {
    margin-top: 4rem;
    flex-direction: column;
    gap: 5rem;
    height: 50rem;
    padding: 0;
    margin-bottom: 10rem;
  }

  .cards > :nth-child(1) {
    left: 12rem !important;
  }
  .cards > :nth-child(2) {
    left: -2.5rem !important;
  }
  .cards > :nth-child(3) {
    left: 12rem !important;
  }
}

@media screen and (orientation: landscape) and (max-width: 961px) {
  .cards > :nth-child(1) {
    left: 19rem !important;
  }
  .cards > :nth-child(2) {
    left: 0rem !important;

  }
  .cards > :nth-child(3) {
    left: 16rem !important;

  }
}

@media screen and (min-width: 962px) and (max-width: 1023px) and (orientation: landscape) {
  .services {
    margin-top: 4rem;
    flex-direction: column;
    gap: 5rem;
    height: 50rem;
    padding: 0;
    margin-bottom: 10rem;
  }

  .cards > :nth-child(1) {
    position: absolute;
    left: 25rem !important;
  }
  .cards > :nth-child(2) {
    left: 2rem !important;
  }
  .cards > :nth-child(3) {
    left: 22rem !important;
  }
}
